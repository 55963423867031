<template>
  <div class="default-padding">
    <div class="header">
      <div class="brand">daniels holden</div>
      <ul>
        <router-link
          v-for="route in this.routeNames"
          :key="route"
          :to="`/${route.replace('home', '')}`"
        >
          <li>{{route}}</li>
        </router-link>
      </ul>
    </div>
  </div>
</template>

<script>
  import { routeNames } from '../../../data/constants';
  export default {
    name: 'Header',
    data() {
      return {
        routeNames,
      };
    },
  };
</script>

<style lang="scss">
  a.router-link-exact-active:not(.nav-item) {
    background: $black;
    li {
      color: $white;
    }
  }
</style>

<style lang="scss" scoped>

  .header {
    padding: 30px 0;
    height: 80px;
    display: flex;
    justify-content: space-between;

    ul {
      list-style-type: none;
    }
  }

  .brand{
    text-transform: uppercase;
    padding-top: 5px;
    font-weight: bold;

    a {
      color: $black;
      font-weight: 900 !important;
      font-size: 18px;
    }
  }

  ul {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
  }

  li {
    color: $black;
    text-transform: uppercase;
    font-weight: 500;
    padding: 8px 8px 0;
    line-height: 11px;
  }

  a {
    margin-right: 40px;

    &:last-child{
      margin-right: 0;
    }
  }

  @media (max-width: $table-screen) {
    .brand {
      display: none;
    }
  }

  @media (max-width: $mobile-screen) {
    .header {
      display: none;
    }
  }
</style>