<template>
  <div class="social-networks">
    <div v-for="network in this.socialNetworks" :key="network.name" class="network-item">
      <a :href="network.link" target="_blank">
        <img :src="`/images/social-networks/${network.name}.svg`" :title="name" />
      </a>
    </div>
  </div>
</template>

<script>
  import { socialNetworks } from '../data/constants';
  export default {
    name: 'SocialNetworks',
    data() {
      return {
        socialNetworks,
      };
    },
  }
</script>

<style lang="scss" scoped>
  .social-networks{
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px 0;
  }

  .network-item{
    cursor: pointer;
    margin: 0 15px;

    &:last-child{
      margin-bottom: 0;
    }
  }

  img{
    filter: invert(0%) sepia(1%) saturate(5%) hue-rotate(54deg) brightness(25%) contrast(100%);
  }

  @media (max-width: $mobile-screen) {
    .network-item {
      margin: 0 auto;
    }
  }
</style>
