<template>
  <header>
    <mobile-header v-if="this.showMobileMenu"/>
    <Header v-else/>
  </header>
</template>

<script>
  import Header from './Header';
  import MobileHeader from './MobileHeader';
  export default {
    name: 'HeaderContainer',
    components: {
      Header,
      MobileHeader,
    },
    data() {
      return {
        showMobileMenu: this.isMobileWidth(),
      }
    },
    mounted() {
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      })
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.onResize);
    },
    methods: {
      onResize() {
        this.showMobileMenu = this.isMobileWidth();
      },
      isMobileWidth() {
        return window.innerWidth <= 550;
      }
    }
  };
</script>
