<template>
  <div id="app">
    <div :class="['app-container', pathName]">
      <div class="ukraine-support-flag"/>
      <header-container/>
        <div class="inner-content">
          <router-view/>
      </div>
      <Footer/>
    </div>
  </div>
</template>

<script>
  import HeaderContainer from './components/Layout/HeaderContainer/HeaderContainer';
  import Footer from './components/Layout/Footer';
  export default {
    name: 'App',
    components: {
      HeaderContainer,
      Footer,
    },
    computed: {
      pathName() {
        return this.$route.name;
      },
    }
  }

</script>

<style lang="scss">
  @import 'assets/styles/mixins';

  .inner-content{
    min-height: calc(100vh - 230px);
    height: 100%;
    
    @media (max-width: $mobile-screen) {
      min-height: calc(100vh - 150px);
    }
  }

  .default-padding {
    @include default-padding
  }

  .ukraine-support-flag {
    height: 70px;
    background: linear-gradient(to bottom, #3737f2 51%, #ffe82f 51% );
  }

  .app-container{
    height: 100%;
    width: 100%;
    background: $white;

    //override global styles
    &.root-path{
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;

      .header {
        li {
          font-weight: 500;
          color: $black;
        }

        a.router-link-exact-active {
          background: $black;

          li {
            color: white;
          }
        }
      }
    }
  }

  @media (min-width: $table-screen + 1px) {
    .app-container.root-path {
      .inner-content .social-networks {
        width: initial;
        display: initial;

        .network-item:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }
  }

  @media (max-width: $table-screen) {
    .root-path{
      background-repeat: round;
    }

    .header ul {
      justify-content: space-evenly;
    }
  }
</style>
