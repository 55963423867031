<template>
  <div class="copyright">
    <span>
      Copyright {{ new Date().getFullYear() }} | Daniels Holden | All rights reserved
    </span>
  </div>
</template>

<script>
  export default {
    name: 'Copyright',
  };
</script>

<style lang="scss" scoped>
  .copyright{
    text-align: center;
    color: $black;
    padding-bottom: 12px;
    font-size: 14px;

    @media (max-width: $mobile-screen) {
      font-size: 12px;
    }

    img{
      height: 20px;
      width: 20px;
      margin-right: 7px;
    }


  }
</style>
