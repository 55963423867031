import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'root-path',
    component: () => import('../views/Main.vue')
  },
  {
    path: '/profile',
    name: 'profile-page',
    component: () => import('../views/Profile.vue')
  },
  {
    path: '/blog',
    name: 'blog-page',
    component: () => import('../views/Blog.vue')
  },
  {
    path: '/blog/:postId',
    name: 'blog-page',
    component: () => import('../views/Post.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: 'not-found-page',
    component: () => import('../views/NotFound.vue')
  },
];

export const router = new VueRouter({ mode: 'history', routes });