export const socialNetworks = [
  {
    name: 'instagram',
    link: 'https://www.instagram.com/danielsholden',
  },
  {
    name: 'twitter',
    link: 'https://twitter.com/danielshoIden',
  },
  {
    name: 'linkedin',
    link: 'https://www.linkedin.com/in/danielsholden',
  },
  {
    name: 'telegram',
    link: 'https://t.me/danielsholden',
  },
  {
    name: 'youtube',
    link: 'https://www.youtube.com/@danielsholden',
  },
];

export const routeNames = ['home', 'blog']; // /profile route is hidden

export const employment = [
  {
    company: 'JDILab',
    position: 'Full Stack Web Developer',
    startDate: 'Aug 2016',
    endDate: 'Apr 2018',
    location: 'Taganrog',
  },
  {
    company: 'Dunice',
    position: 'Full Stack Web Developer',
    startDate: 'Jun 2018',
    endDate: 'Dec 2020',
    location: 'Taganrog',
    link: 'https://www.dunice.net',
  },
  {
    company: 'Open Bank',
    position: 'Frontend Developer',
    startDate: 'Jan 2021',
    endDate: 'Current time',
    location: 'Remote',
    link: 'https://www.open.ru/',
  }
];

export const stackTop = [
  {
    header: 'Programming languages',
    skills: 'Javascript,Typescript,Ruby,HTML,CSS',
  },
  {
    header: 'Frameworks',
    skills: 'React.js,Ruby on Rails,Redux,Thunk/Saga,Socket.io,Vue.js,Bootstrap,Material-UI,Less,Sass,JQuery',
  },
  {
    header: 'SQL and No SQL Databases',
    skills: 'MySQL,PostgreSQL,MongoDB,Redis,ElasticSearch',
  }
];

export const stackBot = [
  {
    header: 'DevOps and Cloud Services',
    skills: 'Nginx,Docker,SSH,Heroku,AWS',
  },
  {
    header: 'Integrations',
    skills: 'Twilio,Stripe,Braintree,Google API,Zendesk,Sendgrid/Mailgun,Yandex Maps,Telegram Bot API',
  },
  {
    header: 'Protocols',
    skills: 'REST,GraphQL,HTTP',
  }
];

//emailJs
export const userID = 'user_0Dy9tZt4ymSwoRmXAGnbD';
export const serviceId = 'service_qcyywkd';
export const templateId = 'template_g9a8xqo';

export const monthsList = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
