<template>
  <footer class="footer default-padding">
    <social-networks />
    <copyright/>
  </footer>
</template>

<script>
  import SocialNetworks from '../../components/SocialNetworks';
  import Copyright from '../Copyright';
  export default {
    name: 'Footer',
    components: {
      Copyright,
      SocialNetworks,
    },
  };
</script>

<style lang="scss" scoped>
  footer{
    position: relative;
    height: 80px;
    bottom: 0;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
  }

  .copyright img{
    height: 20px;
    width: 20px;
    margin-right: 7px;
  }
</style>