<template>
  <div class="slide-menu">
    <div :class="this.getClasses('menu')">
      <p
        v-for="route in this.routeNames"
        :key="route"
        class="nav-item"
        @click="() => handleClick(route)"
      >
        {{route}}
      </p>
      <copyright />
    </div>
    <div
      :class="this.getClasses('menu__toggler')"
      @click="toggleMenu"
    >
      <span/>
    </div>
  </div>
</template>

<script>
  import { routeNames } from '../../../data/constants';
  import Copyright from '../../Copyright';
  export default {
    name: 'MobileHeader',
    components: {Copyright},
    data() {
      return {
        routeNames,
        showMenu: false,
      };
    },
    methods: {
      handleClick(path) {
        this.toggleMenu();
        const route = `/${path.replace('home', '')}`;
        this.$router.push(route);
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
      },
      getClasses(baseClass) {
        return [baseClass, { 'active' : this.showMenu, 'black': this.$route.name === 'root-path' }];
      }
    },
    computed: {
      pathName() {
        return this.$route.name;
      },
    }
  };
</script>

<style lang="scss" scoped>
  .nav-item{
    cursor: pointer;
  }

  .menu__toggler {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 999;
    height: 28px;
    width: 28px;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;

    &.active {
      position: fixed;
    }

    span,
    span::before,
    span::after {
      position: absolute;
      content: '';
      width: 28px;
      height: 2.5px;
      background: $black;
      border-radius: 20px;
      transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
    }

    span::before {
      top: -8px;
    }

    span::after {
      top: 8px;
    }

    &.active > span  {
      background: transparent;
    }

    &.active > span::before,
    &.active > span::after {
      background: $black;
      top: 0;
    }

    &.active > span::before  {
      transform: rotate(-225deg);
    }

    &.active > span::after  {
      transform: rotate(225deg);
    }
  }

  /*
   * SLIDING MENU PANEL
   */
  .menu {
    top: 0;
    position: fixed;
    left: -30%;
    z-index: 998;
    color: $black;
    background: $white;
    width: 30%;
    height: 100%;
    padding: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: 300ms left cubic-bezier(0.77, 0, 0.175, 1);

    &.active {
      left: 0;
    }

    p {
      font-size: 1.4rem;
      margin-bottom: 1rem;
      text-transform: uppercase;
    }

    @media only screen and (max-width: 600px) {
      width: 100%;
      left: -100%;
      padding: 50px;
    }
  }

  .copyright {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    display: block;
    font-size: 13px;
    font-weight: 300;
  }
</style>
