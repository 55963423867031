import Vue from 'vue'
import App from './App.vue'
import { router } from './router'
import { store } from './store'
import PrismicVue from '@prismicio/vue'
import 'vue-toast-notification/dist/theme-default.css';

import "./assets/styles/base.scss";
import VueToast from "vue-toast-notification";

const isIE = navigator.appName === 'Microsoft Internet Explorer' ||
  !!navigator.userAgent.match(/Trident/g) ||
  !!navigator.userAgent.match(/MSIE/g) ||
  navigator.userAgent.match(/rv:11/);

const alertMsg = 'Internet Explorer is not supported, please use another modern browser';

// Add this before the new Vue instance
Vue.use(PrismicVue, {
  endpoint: 'https://danielsholden.cdn.prismic.io/api/v2'
});

Vue.use(VueToast);

isIE ? alert(alertMsg) : new Vue({ router, store, render: h => h(App) }).$mount('#app');